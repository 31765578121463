<template>
  <CRow v-if="mitarbeiter">
    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-https"/>
          Passwort ändern für {{mitarbeiter.name}}
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm @submit.prevent="changePassword(mitarbeiter.mitarbeiterid, plainPassword)">

              <MitarbeiterPassword v-model="plainPassword" />
              <FormError v-if="formError" :errormessage="formError"/>

              <CRow>
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Passwort Ändern</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import MitarbeiterPassword from '@/components/FormElements/Password'

export default {
  name: 'BenutzerPassword',
  components: {
    MitarbeiterPassword,
    FormError
  },
  props: {
    uuid: [Number, String]
  },
  mounted () {
    this.loadMitarbeiter()
  },
  data () {
    return {
      plainPassword: '',
      mitarbeiter: null,
      formError: null
    }
  },
  methods: {
    changePassword (mitarbeiterid, password) {
      Vue.axios.patch('/mitarbeiter/password/change/' + mitarbeiterid, {
        plainPassword: password
      }).then(() => {
        this.$snotify.success('Das Passwort wurde geändert.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.formError = null
        this.plainPassword = ''
        this.$router.push({ path: '/mitarbeiter' })
      }).catch((error) => {
        this.formError = error.response.data
      })
    },
    loadMitarbeiter () {
      if (this.uuid) {
        Vue.axios.get('/mitarbeiter/get/user/' + this.uuid)
          .then((response) => {
            this.mitarbeiter = response.data[0]
          })
      }
    }
  }
}
</script>
